import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "card border-light health-leads-tasks" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "card-body" }
const _hoisted_4 = { class: "card border-light mt-5" }
const _hoisted_5 = {
  key: 0,
  class: "card-body card-custom-spacing-tblf-10"
}
const _hoisted_6 = {
  key: 1,
  class: "card-body"
}
const _hoisted_7 = { class: "card-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vc_user_first_layer = _resolveComponent("vc-user-first-layer")!
  const _component_vc_user_table = _resolveComponent("vc-user-table")!
  const _component_vc_user_last_layer = _resolveComponent("vc-user-last-layer")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_vc_user_first_layer)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      (!_ctx.loading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createVNode(_component_vc_user_table, { class: "mx-4" })
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_6, "Loading Information Please Wait...")),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_vc_user_last_layer)
      ])
    ])
  ]))
}