
import { computed, defineComponent, onMounted } from 'vue'
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Users from '@/core/services/Callable/Users'
import router from '@/router/clean';
import { store } from '@/store'

export default defineComponent({
    setup() {
        
        const listing = computed(() => {
            return store.state.user.user_list_payload
        })

        const loading = computed(() => {
            return store.state.user.user_loading
        })
        
        onMounted(async() => {
            setCurrentPageBreadcrumbs("Users Listing", []);
            const page = router.currentRoute.value.query ? router.currentRoute.value.query.page : 1;
            await getLists(page)
        })

        const getLists = async (value: any) => {
            const page = parseInt(value ? value : 1);
            listing.value.index = page;
            router.replace({ query: { page } })
                .catch(error => {
                    if (
                        error.name !== 'NavigationDuplicated' &&
                        !error.message.includes('Avoided redundant navigation to current location')
                    ) {
                        console.log(error)
                    }
                });
            await Users.getUsers(listing.value)
        }

        return {
            listing,
            loading
        }
    },
})
